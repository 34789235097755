var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workbench-container",style:({
    width: _vm.bigscreenWidthInWorkbench + 'px',
    height: _vm.bigscreenHeightInWorkbench + 'px'
  }),on:{"mousedown":_vm.handleMouseDown}},[_c('vue-ruler-tool',{staticClass:"vueRuler",attrs:{"step-length":50,"parent":true,"position":'relative',"is-scale-revise":true,"visible":_vm.dashboard.presetLineVisible},on:{"update:visible":function($event){return _vm.$set(_vm.dashboard, "presetLineVisible", $event)}},model:{value:(_vm.dashboard.presetLine),callback:function ($$v) {_vm.$set(_vm.dashboard, "presetLine", $$v)},expression:"dashboard.presetLine"}},[_c('div',{staticClass:"workbench",style:({
        transform: _vm.workbenchTransform,
        width: _vm.bigscreenWidth + 'px',
        height: _vm.bigscreenHeight + 'px',
        'background-color': _vm.dashboard.backgroundColor,
        'background-image': 'url(' + _vm.dashboard.backgroundImage + ')',
        'background-position': '0% 0%',
        'background-size': '100% 100%',
        'background-repeat': 'initial',
        'background-attachment': 'initial',
        'background-origin': 'initial',
        'background-clip': 'initial'
      }),attrs:{"id":"workbench"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.setOptionsOnClickScreen($event)}}},[(_vm.grade)?_c('div',{staticClass:"bg-grid"}):_vm._e(),_vm._l((_vm.widgets),function(widget,index){return _c('widget',{key:index,ref:"widgets",refInFor:true,attrs:{"index":index,"step":_vm.step,"type":widget.type,"bigscreen":{ bigscreenWidth: _vm.bigscreenWidth, bigscreenHeight: _vm.bigscreenHeight }},on:{"onActivated":_vm.setOptionsOnClickWidget},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.rightClick($event, index)},"mousedown":function($event){$event.preventDefault();return _vm.widgetsClick(index)},"mouseup":function($event){$event.preventDefault();return _vm.widgetsMouseup($event)}},model:{value:(widget.value),callback:function ($$v) {_vm.$set(widget, "value", $$v)},expression:"widget.value"}})})],2)]),_c('content-menu',{attrs:{"visible":_vm.visibleContentMenu,"style-obj":_vm.styleObj,"widgets":_vm.widgets,"rightClickIndex":_vm.rightClickIndex},on:{"update:visible":function($event){_vm.visibleContentMenu=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }